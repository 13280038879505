<template>
  <b-container>
    <template>
      <h2 class="mb-4">Services Requiring Attention</h2>
      <ajax-table
        ref="services-requiring-attention"
        :select-mode="'single'"
        :should-enable-checkbox="preventSelectCheckBoxes"
        :table-definition="servicesDefinition"
        :no-local-sorting="true"
        @loaded="onLoad"
        emptyText="You're all caught up!"
        hover
        @row-clicked="toggleFormFor"
      >
        <template #cell(company.name)="data">
          <span class="h6 btn-link">{{ data.value }}</span>
        </template>
      </ajax-table>
      <item-requiring-attention-modal ref="item-requiring-attention-modal" @completed="completed" />
    </template>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ServicesRequiringAttention',

  components: {
    AjaxTable: () => import('@/components/shared/AjaxTable'),
    ItemRequiringAttentionModal: () => import('@/components/ItemRequiringAttentionModal'),
  },
  data() {
    return {
      currentItemId: null,
      dataCount: null,
      services: [],
    }
  },
  computed: {
    ...mapGetters('account', ['servicesRequiringAttentionCount']),
    servicesDefinition() {
      return {
        columns: [
          { key: 'company.name', label: 'Company' },
          { key: 'product.description', label: 'Product' },
          { key: 'status', label: 'Status', formatter: value => this.formatStatus(value) },
        ],
        url: 'client/services',
        parameters: {
          include_company: true,
          include_jurisdiction: true,
          include_schema_version: true,
          status: 'awaiting-client-input',
        },
        defaultOrderBy: 'company.name',
        defaultOrderDirection: 'asc',
      }
    },
    noServices() {
      return this.services.length === 0
    },
  },
  methods: {
    ...mapActions('account', ['setServicesRequiringAttentionCount']),
    async onLoad(tableData) {
      this.services = tableData
    },
    preventSelectCheckBoxes() {
      return false
    },
    formatStatus(status) {
      return status
        .split('-')
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(' ')
    },
    async completed(item) {
      this.$root.$emit('bv::toggle::collapse', item.id)
      if (this.servicesRequiringAttentionCount && this.servicesRequiringAttentionCount > 0) {
        await this.setServicesRequiringAttentionCount(this.servicesRequiringAttentionCount - 1)
      } else {
        await this.setServicesRequiringAttentionCount(0)
      }
      await this.$refs['services-requiring-attention'].reload()
      this.closeItemRequiringAttentionModal()
    },
    async toggleFormFor(item) {
      this.$root.$emit('bv::toggle::collapse', item.id)
      this.currentItemId = this.currentItemId === item.id ? null : item.id
      await this.showItemRequiringAttentionModal(item)
    },
    async showItemRequiringAttentionModal(item) {
      await this.$refs['item-requiring-attention-modal'].setItem(item)
      await this.$refs['item-requiring-attention-modal'].setServices(true)
      this.$refs['item-requiring-attention-modal'].open()
    },
    closeItemRequiringAttentionModal() {
      this.$refs['item-requiring-attention-modal'].close()
    },
  },
}
</script>
